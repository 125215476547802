<template>
 <div :class="windowClass">
   <v-container>
    <v-row>
      <v-col cols="12">
                <v-card>
               <v-toolbar>
                 <v-icon left color="primary">mdi-account-multiple-check</v-icon> Breeders
                 <v-spacer/>
                 <span style="width: 290px;">
                        <v-text-field
                            v-model="search"
                            append-icon="mdi-magnify"
                            label="Search"
                            single-line
                            dense
                            outlined
                            hide-details
                        ></v-text-field>
                 </span>
                 <v-divider vertical inset class="ml-5 mr-2"/>
                        <v-btn text small color="success"  @click="user={}, state='new', form=true"><v-icon small left color="success">mdi-plus</v-icon>  Add Breeder</v-btn>
               </v-toolbar>
               <v-card-text>
                 
                <v-data-table
                    class="va-table"
                    dense
                    :headers="headers"
                    :search="search"
                    :items="breeders"
                    >
                    <template v-slot:body="{ items }">
                        <tbody>
                        <tr v-for="(item,index) in items" :key="index">
                            <td>
                            <v-icon  x-small left color="warning" @click="breeder=item, state='update', form=true" >mdi-pencil</v-icon>
                            {{ item.name }}</td>
                            <td class="pa-2">
                              Owner: {{ item.meta.owner }} 
                              <br/> Address: {{ item.meta.address }}
                              <br/> Contact #: {{ item.meta.contact_no }}
                            </td>
                            <td>{{ item.registered_at | moment("YYYY-MM-DD") }}</td>
                             <td :class="STATUS_COLOR(item.status)">{{ item.status }}</td>
                           
                        </tr>
                        </tbody>
                    </template>
                    </v-data-table>
               </v-card-text>
             </v-card>
          </v-col>
         
    </v-row>
    </v-container>
    <va-breeder-form :show="form" :breeder_id="breeder.id" :state="state" @DialogEvent="formEvent" />
    <!-- <va-area-form :show="areaform" :area="area" :state="state" @DialogEvent="afEvent" /> -->
 
  </div>
</template>
<script>
import {
  mapMutations
} from 'vuex'
  export default {
    data: () => ({
        form: false,
        trans_dailog: false,
        areaform: false,
        state: 'new',
        breeder: {},
        search:'',
        breeders:[],
        headers: [
            { text: 'Name', value:"name"},
            { text: 'Meta', value:"username"},
            { text: 'Registered', value:"registered_at"},
            { text: 'Status', value:"status"}
        ]
    }),
    beforeCreate: function () {
      if (!this.$session.exists()) {
        this.$router.push('/')
      }
    },
    created() {
       if (this.$session.exists()) {
          this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.$session.get('jwt')
          this.setLoggedIn(true) 
          this.getItems()
         
        }
    },
    computed:{
       windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
    },
    methods: {
      ...mapMutations(['setAlert', 'setAlertColor', 'setAlertText', 'setLoggedIn']),
     
      afEvent() {
        this.areaform = false
        this.getAreas()
      },
      formEvent(data){
          this.form = false
          if(data.action != 'close') {
              this.breeder = data.breeder
              this.Save()
          }
      },

      getItems: function() { 
        this.$http.get("breeder/list").then(response => {
            response.data.items != null?this.breeders = response.data.items:this.breeders =[]
        }).catch(e => {
        console.log(e.data)
        })
     },
    
      Save: function() {
        let method = 'breeder/add'
        this.state != 'new'?method='breeder/update':method = 'breeder/add'
      console.log(this.breeder)
        this.$http.post(method, this.breeder).then(response => {
            response.data.status? this.VA_ALERT('success', response.data.message): this.VA_ALERT('error', response.data.message)
            this.getItems()
        }).catch(e => {
        console.log(e)
        })
     },
    }
  }
</script>